import Paranoid from "./Paranoid";
import Role from "./Role";
class Account extends Paranoid {
  userId: string = "";
  organizationId: string = "";
  busy: boolean = false;
  active: boolean = false;
  lastCallAssignedDate: Date = new Date();
  lastCallAnsweredDate: Date = new Date();
  callOrder: number = 0;
  isSubscriptionOwner: boolean = false;
  //TODO: This is a mistake in API we sometimes send it as lower case and other times as upper case.
  roles: Array<Role> = [];
  Roles: Array<Role> = [];
}
export default Account;
