<script setup lang="ts">
import { getCurrentInstance, onBeforeMount as onBeforeMountVue } from "vue";
import { onBeforeMount, ref, Ref, reactive } from "vue";
import { useDisplay } from "vuetify";
import { computed } from "vue";
const { smAndDown } = useDisplay();
/*
    Generic table component for re-use in Conceirge.
    Please do not put anything single table specific in here.
    Everything should be passed in or exposed out. 

    Please note there are slots/templates availble for this class:
    Slots:
        - item (used to render a single row in the table, see below for exposed properties) *required


    TABLE NO LONGER RENDERS HEADERS FOR SM AND DOWN RESOLUTIONS. YOU ARE IN CHARGE OF RENDERING IT YOURSELF IN THOSE CASES.
*/

/*
    Table / Store properties.
*/

interface StoreProps {
  store: any;
  where?: {};
  customPath?: string | null; // Handled by GenericStore now.
  orderBy?: Array<any>;
  searchValues?: {} | null;
  pageSize?: number;
  page?: number;
  items?: [];
  skipLoad?: boolean;
  onBeforeMount?: ((items: any) => void) | null;
  onLoaded?: ((items: any) => void) | null;
}

interface HeaderProps {
  title: string;
  key: string;
  icon?: string;
  class?: string;
  sortable?: boolean;
  tooltip?: string;
}

interface ConfigProps {
  headers: HeaderProps[];
  showPaging?: boolean;
  showMobileIcons: boolean;
  noDataText?: string;
  height?: number | undefined;
  hover?: boolean;
  sortFn?: ((a: any, b: any) => number) | null;
  localSort?: ((a: any, b: any) => number) | null;
  noDataImage?: string;
}

interface BannerProps {
  text?: string;
  img?: string;
  color?: string;
  live?: boolean;
}

interface TableProps {
  td?:
    | Record<string, any>
    | ((
        data: Pick<any, "value" | "item" | "index" | "internalItem" | "column">
      ) => Record<string, any>);
  tr?:
    | Record<string, any>
    | ((
        data: Pick<any, "item" | "index" | "internalItem">
      ) => Record<string, any>);
}

export interface TdProps { 
  column: any;
  index: any;
  internalItem: any;
  item: any;
  value: any;
}

const props = defineProps<{
  store: Partial<StoreProps>;
  config: Partial<ConfigProps>;
  heading?: Partial<BannerProps>;
  tableProps?: Partial<TableProps>;
}>();
const emits = defineEmits<{
  (e: "mounted", el: any): void;
}>();
const selfItems: Ref<Array<any>> = ref([]);
const selfTotal: Ref<number> = ref(0);
const selfItemsPerPage: Ref<number> = ref(10);
const loading: Ref<boolean> = ref(true);
const selfPage: Ref<number> = ref(1);
let skipLoad: boolean = false;

// TODO: move to utility.
const getPropertyByKey = <T extends object>(
  props: T,
  key: keyof T,
  defaultValue: any
) => {
  return key in props ? props[key] : defaultValue;
};

const selfStore: StoreProps = reactive<StoreProps>({
  store: null,
  customPath: null,
  where: {},
  orderBy: [],
  pageSize: 10,
  page: 1,
  skipLoad: false,
  searchValues: null,
  onBeforeMount: null,
  onLoaded: null,
  items: [],
});

selfStore.store = getPropertyByKey(props.store, "store", selfStore.store);
selfStore.where = getPropertyByKey(props.store, "where", selfStore.where);
selfStore.customPath = getPropertyByKey(
  props.store,
  "customPath",
  selfStore.customPath
);
selfStore.orderBy = getPropertyByKey(props.store, "orderBy", selfStore.orderBy);
selfStore.onBeforeMount = getPropertyByKey(
  props.store,
  "onBeforeMount",
  selfStore.onBeforeMount
);
selfStore.onLoaded = getPropertyByKey(
  props.store,
  "onLoaded",
  selfStore.onLoaded
);

selfItemsPerPage.value = getPropertyByKey(props.store, "pageSize", 10);
selfPage.value = getPropertyByKey(props.store, "page", 1);
selfItems.value = getPropertyByKey(props.store, "items", []);
if (props.store.items) {
  skipLoad = true;
}
skipLoad = getPropertyByKey(props.store, "skipLoad", false);

const config: ConfigProps = reactive<ConfigProps>({
  headers: [],
  showPaging: true,
  noDataText: "Nothing to show here",
  hover: false,
  height: undefined,
  localSort: null,
  sortFn: null,
  noDataImage: "",
  showMobileIcons: true,
});
config.headers = props.config.headers || config.headers;
config.showPaging = getPropertyByKey(
  props.config,
  "showPaging",
  config.showPaging
);
const noDataText = computed(() =>
  getPropertyByKey(props.config, "noDataText", config.noDataText)
);
config.height = getPropertyByKey(props.config, "height", config.height);
config.showMobileIcons = getPropertyByKey(
  props.config,
  "showMobileIcons",
  config.showMobileIcons
);
config.hover = getPropertyByKey(props.config, "hover", config.hover);
config.localSort = getPropertyByKey(props.config, "sortFn", config.localSort);
config.noDataImage = getPropertyByKey(
  props.config,
  "noDataImage",
  config.noDataImage
);

const heading: BannerProps = reactive<BannerProps>({
  text: "",
  img: "",
  color: "headingBG",
  live: false,
});
heading.text = getPropertyByKey(props.heading || {}, "text", heading.text);
heading.img = getPropertyByKey(props.heading || {}, "img", heading.img);
heading.color = getPropertyByKey(props.heading || {}, "color", heading.color);
heading.live = getPropertyByKey(props.heading || {}, "live", heading.live);

const tableProps: TableProps = reactive<TableProps>({
  td: [],
  tr: [],
});
tableProps.td = getPropertyByKey(props.tableProps || {}, "td", tableProps.td);
tableProps.tr = getPropertyByKey(props.tableProps || {}, "tr", tableProps.tr);
/*
    End table / store properties.
*/

/* 
    Exposed methods to parent containers. 
    These methods can be called using ref. 
*/
const addItem = (item: any) => {
  selfItems.value.push(item);
  if (config.localSort) {
    selfItems.value.sort(config.localSort);
  }
  //TODO: Handle updating paging when item is added.
};
const addOrUpdate = (item: any) => {
  const exists = findItemBy((x: any) => x.id === item.id);
  if (exists) {
    updateItem(item, (x: any) => x.id === item.id);
  } else {
    addItem(item);
  }
};
const getItems = () => {
  return selfItems;
};
const removeItemBy = (fn: (value: any) => any) => {
  selfItems.value = selfItems.value.filter(fn);
  if (config.localSort) {
    selfItems.value.sort(config.localSort);
    // TODO: handle paging when item is removed but not remote.
  } else {
    load();
  }
};
const findItemBy = (fn: (value: any) => any) => {
  return selfItems.value.find(fn);
};
const searchAPIByValues = (searchValues: {}): void => {
  selfStore.searchValues = searchValues;
  load();
};
const updateItem = (item: any, findFn: (value: any) => any) => {
  if (!findFn) {
    findFn = (element) => {
      return element.id == item.id;
    };
  }
  const db = selfItems.value.find(findFn);
  if (db) {
    updateProperties(db, item);
  }
  if (config.localSort) {
    selfItems.value.sort(config.localSort);
  }
};
const load = async () => {
  await loadStore();
};
defineExpose({
  addItem,
  getItems,
  removeItemBy,
  findItemBy,
  updateItem,
  load,
  searchAPIByValues,
  addOrUpdate,
});
/*
    End exposed methods.
*/

/* 
    Methods
*/

// Item are the properties to update, db is the item in our store.
const updateProperties = (db: any, item: any) => {
  Object.keys(item).forEach((key) => {
    if (key != "id") {
      if (checkIfObject(db[key])) {
        updateProperties(db[key], item[key]);
      } else {
        db[key] = item[key];
      }
    }
  });
};

// Helper function to check if property is an object.
// TODO: move to utility.
const checkIfObject = (input: any) => {
  return (
    null !== input &&
    typeof input === "object" &&
    Object.getPrototypeOf(input).isPrototypeOf(Object)
  );
};

// Gets the params to send to the server.
const getStoreParams = () => {
  let where = selfStore.where;
  // Simple implementation that and's the where clauses by ORs the search values.
  if (selfStore.searchValues) {
    const searchValues = {
      $or: {
        ...selfStore.searchValues,
      },
    };
    where = {
      $and: [searchValues, selfStore.where],
    };
  }
  let searchParams = {
    page: selfPage.value,
    pageSize: selfItemsPerPage.value,
    order: JSON.stringify(selfStore.orderBy),
    where: JSON.stringify(where),
  };
  return searchParams;
};

// Loads the store
const loadStore = async () => {
  if (selfStore.store) {
    loading.value = true;
    let results = null;
    if (selfStore.customPath) {
      results = await selfStore.store.getRecordsWithPath(
        getStoreParams(),
        selfStore.customPath
      );
    } else {
      results = await selfStore.store.gets(getStoreParams());
    }

    if (selfStore.onLoaded) {
      results = props.store.onLoaded?.call(this, results) || results;
    }
    if (results?.count) {
      selfTotal.value = results.count;
    } else {
      selfTotal.value = 0;
    }
    if (results?.rows) {
      selfItems.value = results.rows;
    } else {
      selfItems.value = [];
    }
    loading.value = false;
    if (config.localSort) {
      selfItems.value.sort(config.localSort);
    }
  }
};

// Updates the store options, this gets called once on mounted.
// @params e
//  (optional) page
//  (optional) itemsPerPage
//  (opitonal) order
const updateOptions = async (e: {
  page: number;
  itemsPerPage: number;
  sortBy: { key: string; order: "asc" | "desc" };
}) => {
  if (e.page) {
    selfPage.value = e.page;
  }
  if (e.itemsPerPage) {
    selfItemsPerPage.value = e.itemsPerPage;
  }
  if (e.sortBy && Array.isArray(e.sortBy) && e.sortBy.length > 0) {
    selfStore.orderBy = [];
    e.sortBy.forEach((element) => {
      const nestedKeys = element.key.split(".");
      const newItem: string[] = [...nestedKeys, element.order];
      selfStore.orderBy?.push(newItem);
    });
  }
  if (!skipLoad) {
    await loadStore();
  }
};

// Updates the order by if someone hits a heading.
// @params e
//  (required) order
const updateOrderBy = (e: { key: string; order: string }[]) => {};

// Updates the paging by if someone hits the pagination component.
// @params e
//  (required) int
const updatePage = async (e: number) => {
  selfPage.value = e;
};

/*
    End methods.
*/

/* 
    Events
*/

onBeforeMount(async () => {
  // if (!skipLoad) {
  //     loadStore();
  // }
  if (selfStore.onBeforeMount) {
    props.store.onBeforeMount?.call(this, selfItems);
  }
});

// watch(selfPage, async() => {
//     await load();
// })
/*

*/

/*
    End Events
*/
const itemsPerPageOptions = [
  { value: 10, title: "10" },
  { value: 25, title: "25" },
  { value: 50, title: "50" },
  { value: 100, title: "100" },
];

const getItemSlot = (key: string) => {
  return `item.${key}` as keyof {
    [x: `item.${string}`]: ((arg: any) => any) | undefined;
  };
};
</script>
<template>
  <v-data-table-server
    :items="selfItems"
    :headers="config.headers"
    :items-per-page="selfItemsPerPage"
    :items-length="selfTotal"
    :loading="loading"
    loading-text="Loading rows from database."
    item-props
    :row-props="tableProps.tr"
    :cell-props="tableProps.td"
    :height="config.height"
    @update:options="updateOptions"
    @update:sortBy="updateOrderBy"
    @update:page="updatePage"
    sort-asc-icon="icon-arrow-down"
    sort-desc-icon="icon-arrow-up"
    show-current-page
    :hover="config.hover"
    class="generic-table"
    :itemsPerPageOptions="itemsPerPageOptions"
    itemsPerPageText="Rows per page"
    v-bind="$attrs"
    fixed-header
    elevation="3"
    @vue:mounted="
      (el : any) => {
        emits('mounted', el);
      }
    "
  >
    <template
      v-slot:headers="{
        headers,
        columns,
        sortBy,
        someSelected,
        allSelected,
        toggleSort,
        selectAll,
        getSortIcon,
        isSorted,
      }"
    >
      <tr v-if="!smAndDown">
        <template v-for="column in columns" :key="column.key">
          <th
            v-on:click="column.sortable ? toggleSort(column) : undefined"
            :class="[(column as any).class, column.sortable ? 'sortable' : '']"
          >
            <v-icon
              v-if="(column as any).icon"
              class="mr-1"
              :icon="(column as any).icon"
            ></v-icon>
            <span
              class="mr-2 cursor-pointer"
              :title="column.title"
              v-html="column.title"
            ></span>
            <v-icon
              v-if="(column as any).tooltip"
              icon="icon-circle-help-stroke"
              class="mb-1"
            ></v-icon>
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)"></v-icon>
            </template>
            <v-tooltip
              v-if="(column as any).tooltip"
              activator="parent"
              location="bottom"
              max-width="300"
              ><p v-html="(column as any).tooltip"></p
            ></v-tooltip>
          </th>
        </template>
      </tr>
    </template>

    <template v-if="heading.text" v-slot:top="{}">
      <v-toolbar
        prominent
        elevation="0"
        :color="heading.color"
        height="152"
        rounded
        style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
      >
        <v-row class="fill-height" no-gutters>
          <v-col cols="6" lg="12" style="margin-top: 20px; margin-left: 20px">
            <div class="text-h4 font-weight-bold" style="margin-bottom: 4px">
              {{ heading.text }}
            </div>
            <div v-if="heading.live" class="live">LIVE</div>
          </v-col>
        </v-row>
        <template v-slot:image>
          <v-spacer></v-spacer><img inline :src="heading.img" class="mr-1" />
        </template>
      </v-toolbar>
    </template>

    <template v-for="header in config.headers" :key="header.key" v-slot:[getItemSlot(header.key)]="{ index, item, internalItem, isExpanded, toggleExpand, isSelected, toggleSelect}">
      <div class="mobile-display" v-if="smAndDown">
        <div class="font-weight-bold" v-if="config.showMobileIcons">
          <template v-if="header.icon">
            <v-icon :class="header.icon" class="mr-1 mb-1"></v-icon> </template
          ><span v-html="header.title"></span>
        </div>          
        <slot
          :name="header.key"
          :index="index"
          :item="item"
          :isExpanded="isExpanded"
          :toggleExpand="toggleExpand"
          :isSelected="isSelected"
          :toggleSelect="toggleSelect"
        ></slot>
      </div>
      <div class="text-overflow-hidden" v-if="!smAndDown">
        <slot
          :name="header.key"
          :index="index"
          :item="item"
          :isExpanded="isExpanded"
          :toggleExpand="toggleExpand"
          :isSelected="isSelected"
          :toggleSelect="toggleSelect"
        ></slot>
      </div>
    </template>

    <template
      v-if="!config.showPaging"
      v-slot:bottom="{
        page,
        itemsPerPage,
        sortBy,
        pageCount,
        toggleSort,
        setItemsPerPage,
        someSelected,
        allSelected,
        isSelected,
        select,
        selectAll,
        toggleSelect,
        isExpanded,
        isGroupOpen,
        toggleGroup,
        items,
        groupedItems,
        columns,
        headers,
      }"
    ></template>

    <template v-slot:no-data>
      <div class="d-flex flex-column flex-nowrap no-data pt-3 w-100">
        <div class="text-center">
          <span
            class="text-h4 text-disabled"
            style="max-width: 100%; white-space: break-spaces"
            >{{ noDataText }}</span
          >
        </div>
        <div class="text-center mt-4">
          <img
            :src="config.noDataImage"
            style="max-height: 145px; opacity: 0.8"
          />
        </div>
      </div>
    </template>
  </v-data-table-server>
</template>
<style scoped lang="scss">
@use "@/styles/settings";
div.generic-table {
  min-width: 205px;
}
div.generic-table th {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02em;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
}

div.generic-table td {
  text-overflow: ellipsis;
  white-space: break-spaces;
  overflow: hidden;
}

div.live {
  color: #fff;
  border-radius: 5.5px;
  background: #000;
  display: inline-flex;
  padding: 2px 8px;
  color: #fff;
  text-align: center;
  font-family: Inter Tight;
  font-size: 0.825rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 200% */
  letter-spacing: 0.056875rem;
}

.generic-table:deep(tbody tr td) {
  height: auto !important;
  min-height: calc(var(--v-table-row-height, 52px) + 0px);
}

.generic-table:deep(table) {
  width: 100%;
}

.generic-table:deep(thead) {
  z-index: 2;
}

.generic-table:deep(thead th.sortable) {
  cursor: pointer;
}

.generic-table:deep(tr.v-data-table-rows-no-data),
.generic-table:deep(tr.v-data-table-rows-no-data td) {
  width: 100%;
}

@media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
  .generic-table:deep(
      tbody:not(:has(tr.v-data-table-rows-no-data)) tr:nth-of-type(odd) td
    ) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th.hidden-icon-desktop i:first-child {
    display: none;
  }

  .generic-table:deep(tbody tr td),
  .generic-table:deep(thead tr th) {
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .generic-table:deep(tbody tr td > div),
  .generic-table:deep(tbody tr td > div > div) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media #{map-get(settings.$display-breakpoints, 'sm-and-down')} {
  .generic-table.v-table.v-table--hover
    > .v-table__wrapper
    > table
    > tbody
    > tr:hover
    td {
    background: initial;
  }

  .mobile-display {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  .generic-table:deep(
      tbody:not(:has(tr.v-data-table-rows-no-data)) tr td:nth-of-type(even)
    ) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .generic-table:deep(table) {
    display: block;
  }

  .generic-table:deep(thead) {
    display: none;
  }

  .generic-table:deep(tbody),
  .generic-table:deep(tr),
  .generic-table:deep(td) {
    flex-wrap: nowrap;
    display: flex;
  }

  .generic-table:deep(tbody),
  .generic-table:deep(tr:not(.v-data-table-rows-no-data)) {
    flex-direction: column;
  }

  .generic-table:deep(tr:not(.v-data-table-rows-no-data)) {
    border-bottom: solid rgba(var(--v-border-color), var(--v-border-opacity));
    border-bottom-width: 12px;
  }

  .generic-table:deep(tbody > tr:last-of-type) {
    border-bottom-width: 0px;
  }

  .generic-table:deep(tr:not(.v-data-table-rows-no-data) td) {
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: break-word;
    align-items: center;
    gap: 8px;
  }

  .generic-table:deep(
      tr:not(.v-data-table-rows-no-data) td > div:last-of-type
    ) {
    text-align: right;
  }

  .generic-table:deep(tr:not(.v-data-table-rows-no-data) > td) {
    width: 100%;
    height: 21px;
  }
}
</style>
<style lang="scss">
@use "@/styles/settings";

.generic-table > .v-table__wrapper > table > tbody > tr > td,
.generic-table > .v-table__wrapper > table > tbody > tr > th,
.generic-table > .v-table__wrapper > table > thead > tr > td,
.generic-table > .v-table__wrapper > table > thead > tr > th,
.generic-table > .v-table__wrapper > table > tfoot > tr > td,
.generic-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 8px 16px;
}

@media #{map-get(settings.$display-breakpoints, 'md-and-down')} {
  .generic-table
    > .v-table__wrapper
    > table
    > tbody
    > tr
    > td:has(div.no-data) {
    padding: 0;
  }
  .generic-table > .v-table__wrapper > table > tbody > tr > td > div.no-data {
    padding-left: 0;
    padding-right: 0;
  }
}

.generic-table:has(header) .v-table__wrapper {
  border-radius: 0;
}

.generic-table.hide-header thead,
.generic-table.hide-header div.mobile-header {
  display: none;
}
.generic-table.hide-header td > div:nth-child(2) {
  width: 100%;
}

.generic-table.v-table.v-table--hover
  > .v-table__wrapper
  > table
  > tbody
  > tr:hover
  td {
  background: rgba(var(--v-theme-primary), 0.05);
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}
</style>
