import { defineStore } from "pinia";
import { Ref, ref, computed, ComputedRef } from "vue";
import { useApiStore } from "./api";
import User from "@/types/User";
import Account from "@/types/Account";
import Organization from "@/types/Organization";

export const useSelfStore = defineStore("selfStore", () => {
  const user: Ref<User> = ref(new User());
  const account: Ref<Account> = ref(new Account());
  const organization: Ref<Organization> = ref(new Organization());
  const organizations: Ref<Array<Organization>> = ref([]);
  const hasOrganization = () => organization.value && organization.value.id;
  const showWizard = () =>
    (organization.value && !organization.value.isSetup) || !hasOrganization();
  const showOrgSwitcher = computed<boolean>(
    () => organizations.value && organizations.value.length > 1
  );
  const canEditWizard = () =>
    isAdmin ||
    !organization ||
    (!organization.value && organizations.value.length > 0);

  let isAdmin = computed<boolean>(
    () =>
      account.value &&
      account.value.roles &&
      account.value.roles.length > 0 &&
      account.value.roles.filter((role) => role.name === "Administrator")
        .length > 0
  );
  let isViewOnly = computed<boolean>(() => {
    return (
      account.value &&
      account.value.roles &&
      account.value.roles.length > 0 &&
      account.value.roles.filter(
        (role) => role.name.toUpperCase() === "VIEW ONLY"
      ).length > 0
    );
  });
  const logoURL: ComputedRef<string> = computed<string>(() => {
    return organization.value?.logoUrl
      ? organization.value?.logoUrl
      : "/default-logo.webp";
  });

  const hasPermission = (permissionName: string) => {
    return (
      account.value &&
      account.value.roles &&
      account.value.roles.length > 0 &&
      account.value.roles[0].permissions.filter(
        (permission) => permission.name == permissionName
      ).length > 0
    );
  };

  const hasRequiredInformationToFunction = () => {
    return (
      organization.value &&
      account.value &&
      user.value &&
      organization.value.id &&
      account.value.id &&
      user.value.id
    );
  };

  const refreshUser = async () => {
    const apiStore = useApiStore();
    try {
      const predefinedOrg = localStorage.getItem("organization_id");
      if (predefinedOrg) {
        apiStore.organizationId = predefinedOrg;
      }
      let resp = await apiStore.getAuth("/me", { includePermissions: true });
      if (resp && resp.data) {
        const raw = resp.data;
        account.value = raw?.account;
        organization.value = raw?.organization;
        organizations.value = raw?.organizations;
        user.value = raw?.user;

        if (raw?.organization?.id) {
          apiStore.organizationId = raw?.organization?.id;
        }
      }
    } catch (e) {
      console.debug("Failed to retrieve user account", e);
      throw e;
    }
  };

  return {
    user,
    account,
    organization,
    organizations,
    showWizard,
    refreshUser,
    isAdmin,
    showOrgSwitcher,
    hasPermission,
    canEditWizard,
    hasOrganization,
    isViewOnly,
    logoURL,
    hasRequiredInformationToFunction,
  };
});
