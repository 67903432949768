/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import { VBtn } from "vuetify/components/VBtn";
import { aliases, ls } from "@/iconsets/liveswitch";
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    defaultSet: "ls",
    aliases,
    sets: {
      ls,
    },
  },
  aliases: {
    ActionBtn: VBtn,
    CancelBtn: VBtn,
    WizardBtn: VBtn,
  },
  defaults: {
    VSelect: {
      variant: "solo",
      elevation: 0,
      flat: true,
    },
    VSnackbar: {
      timeout: "2000",
      location: "top",
    },
    VSwitch: {
      color: "primary",
    },
    VDataTableServer: {
      elevation: 0,
    },
    VTextField: {
      variant: "solo",
      elevation: 0,
      flat: true,
    },
    VBtn: {
      elevation: 0,
    },
    VCard: {
      elevation: 3,
    },
    CancelBtn: {
      color: "#000",
      elevation: 0,
      variant: "outlined",
    },
  },
  locale: {
    locale: "en",
    fallback: "en",
  },
  theme: {
    defaultTheme: "liveswitch",
    themes: {
      liveswitch: {
        dark: false,
        colors: {
          white: "#FFF",
          background: "#FFF",
          surface: "#FFF",
          primary: "#0070FF",
          error: "#E33154",
          info: "#979EAF",
          success: "#00A441",
          warning: "#F19D38",
          orange: "#FB8C00",
          gold: "#DB8B05",
          "orange-light": "FFE0B2",
          indigo: "#7096FB",
          avatar: "#AA7085",
          "indigo-light": "DEE2F7",
          purple: "#403996",
          "purple-light": "ECE3FB",
          tertiary: "#5E6168",
          manateegray: "#9B9EA5",
          altrow: "#F3F5F9",
          headingBG: "#E5EBED",
          "input-error-bg": "#FAEEF0",
          "border-color": "#979EAF",
          "bottom-nav-color": "#707070",
          "bottom-nav-selected-color": "#09214A",
          red: "#E84867",
          "red-lighten-5": "#FAEEF0",
          "red-lighten-4": "#FDDDE3",
          "red-lighten-3": "#F9C6CF",
          "red-lighten-2": "#F1AAB7",
          "red-lighten-1": "#E17083",
          "red-darken-1": "#AC4454",
          "red-darken-2": "#80323F",
          "red-darken-3": "#6B2A35",
          "red-darken-4": "#401920",
          green: "#00BA4A",
          "green-lighten-5": "#EBF9EF",
          "green-lighten-4": "#D6F3DF",
          "green-lighten-3": "#9DE3B0",
          "green-lighten-2": "#8BDCA1",
          "green-lighten-1": "#4CBD63",
          "green-darken-1": "#3F8B47",
          "green-darken-2": "#35773D",
          "green-darken-3": "#2C6333",
          "green-darken-4": "#183C1F",
          blue: "#0070FF",
          "blue-lighten-5": "#F5F9FF",
          "blue-lighten-4": "#E5EFFE",
          "blue-lighten-3": "#B8D4FF",
          "blue-lighten-2": "#90BAFF",
          "blue-lighten-1": "#4D8BF7",
          "blue-darken-1": "#2559C5",
          "blue-darken-2": "#1F4DAD",
          "blue-darken-3": "#14377B",
          "blue-darken-4": "#09214A",
          grey: "#9E9E9E",
          "grey-lighten-5": "#F9FAFC",
          "grey-lighten-4": "#F3F5F9",
          "grey-lighten-3": "#EBEEF5",
          "grey-lighten-2": "#DDE0E7",
          "grey-lighten-1": "#BABDC4",
          "grey-darken-1": "#72757C",
          "grey-darken-2": "#5E6168",
          "grey-darken-3": "#3F4249",
          "grey-darken-4": "#1E2128",
          "indigo-darken-3": "#283593",
        },
      },
    },
  },
});
