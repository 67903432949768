<template>
  <v-dialog
    v-bind:model-value="dialogVisible"
    max-width="500px"
    @input="$emit('input', $event)"
    persistent
  >
    <v-card>
      <v-card-title>{{ $props.title }}</v-card-title>
      <v-card-text style="padding-top: 0px">
        {{ $props.message }}
        <slot>
          <!-- Alternative to message for more complex displays. -->
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer
          v-if="!$props.hideCancelButton || !$props.hideActionButton"
        ></v-spacer>
        <slot name="footer"></slot>

        <CancelBtn
          variant="plain"
          @click="$emit('close-dialog')"
          v-if="!$props.hideCancelButton"
        >
          {{ cancelButtonText }}
        </CancelBtn>
        <v-btn
          v-if="$props.actionButtonText && !$props.hideActionButton"
          variant="flat"
          :disabled="!canSave"
          :color="actionButtonColor"
          @click="
            () => {
              actionClick();
              $emit('do-action');
            }
          "
          :loading="dialogLoading"
        >
          {{ $props.actionButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, ComputedRef, type Ref, ref, watchEffect } from "vue";
const emit = defineEmits(["close-dialog", "do-action", "input"]);
const props = defineProps<{
  dialogVisible: boolean;
  dialogLoading?: boolean;
  hideCancelButton?: boolean;
  hideActionButton?: boolean;
  title: string;
  message: string;
  actionButtonText: string;
  cancelButtonText?: string;
  actionButtonTextColor?: string;
}>();
const canSave: Ref<boolean> = ref(true);
const cancelButtonText: ComputedRef<string> = computed<string>(
  () => props.cancelButtonText ?? "Cancel"
);
const actionButtonColor: ComputedRef<string> = computed<string>(
  () => props.actionButtonTextColor ?? "primary"
);
const actionClick = () => {
  canSave.value = false;
  setTimeout(() => {
    if (canSave) {
      canSave.value = true;
    }
  }, 500);
};
watchEffect(() => {
  if (props.dialogVisible == true) {
    canSave.value = true;
  }
});
</script>
